import React, {Component} from "react"
import styles from "./Footer.module.css"
import edularLogo from "../Header/parts/img/edular-logo.jpg"

const campuses = []
const campuses1 = []
const campuses2 = []
const campuses3 = []
const campuses4 = []
const campuses5 = []
const campuses6 = []

const social = [
    {
        id: 1,
        name: "Facebook",
        link: "https://www.klassapps.com",
        icon: "fab fa-facebook"
    },
    {
        id: 2,
        name: "Instagram",
        link: "https://www.klassapps.com",
        icon: "fab fa-instagram"
    },
    {
        id: 3,
        name: "Twitter",
        link: "https://www.klassapps.com",
        icon: "fab fa-twitter"
    },
    {
        id: 4,
        name: "Youtube",
        link: "https://www.klassapps.com",
        icon: "fab fa-youtube"
    },
    {
        id: 5,
        name: "Pinteres",
        link: "https://www.klassapps.com",
        icon: "fab fa-pinterest"
    },
    {
        id: 6,
        name: "TikTok",
        link: "https://www.klassapps.com",
        icon: "fab fa-tiktok"
    }
]

interface Props {}

export class Footer extends Component<Props, {}> {
    public render() {
        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <div>
                        <div>
                            <img src={edularLogo} alt="edular-logo" className={styles.imgLogo} />
                        </div>
                        <div className={styles.social}>
                            {social.map(({id, link, icon}) => (
                                <a
                                    href={link}
                                    className={`${icon} aria-hidden="true`}
                                    key={id}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {""}
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className={styles.wrapper2}>
                        <div className={styles.campuses}>
                            <h5>
                                <strong>{""}</strong>
                            </h5>
                            {campuses.map(({name, code, address, phone, state, id}) => (
                                <div className={styles.campusContainer} key={id}>
                                    {/* <div className={styles.name}>
                                        <strong>{name}</strong>
                                    </div> */}
                                    <div className={styles.code}>
                                        <strong>{code}</strong>
                                    </div>
                                    <div className={styles.address}>{address}</div>
                                    <div className={styles.address}>{state}</div>
                                    <div className={styles.phone}>
                                        <strong>Ph:</strong> {phone}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.campuses}>
                            <h5>
                                <strong>{""}</strong>
                            </h5>
                            {campuses3.map(({name, code, address, phone, state, id}) => (
                                <div className={styles.campusContainer} key={id}>
                                    {/* <div className={styles.name}>
                                        <strong>{name}</strong>
                                    </div> */}
                                    <div className={styles.code}>
                                        <strong>{code}</strong>
                                    </div>
                                    <div className={styles.address}>{address}</div>
                                    <div className={styles.address}>{state}</div>
                                    <div className={styles.phone}>
                                        <strong>Ph:</strong> {phone}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.campuses}>
                            <h5>
                                <strong>{""}</strong>
                            </h5>
                            {campuses1.map(({name, code, address, phone, state, id}) => (
                                <div className={styles.campusContainer} key={id}>
                                    {/* <div className={styles.name}>
                                        <strong>{name}</strong>
                                    </div> */}
                                    <div className={styles.code}>
                                        <strong>{code}</strong>
                                    </div>
                                    <div className={styles.address}>{address}</div>
                                    <div className={styles.address}>{state}</div>
                                    <div className={styles.phone}>
                                        <strong>Ph:</strong> {phone}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.campuses}>
                            <h5>
                                <strong>{""}</strong>
                            </h5>
                            {campuses2.map(({name, code, address, phone, state, id}) => (
                                <div className={styles.campusContainer} key={id}>
                                    {/* <div className={styles.name}>
                                        <strong>{name}</strong>
                                    </div> */}
                                    <div className={styles.code}>
                                        <strong>{code}</strong>
                                    </div>
                                    <div className={styles.address}>{address}</div>
                                    <div className={styles.address}>{state}</div>
                                    <div className={styles.phone}>
                                        <strong>Ph:</strong> {phone}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.campuses}>
                            <h5>
                                <strong>{""}</strong>
                            </h5>
                            {campuses4.map(({name, code, address, phone, state, id}) => (
                                <div className={styles.campusContainer} key={id}>
                                    {/* <div className={styles.name}>
                                        <strong>{name}</strong>
                                    </div> */}
                                    <div className={styles.code}>
                                        <strong>{code}</strong>
                                    </div>
                                    <div className={styles.address}>{address}</div>
                                    <div className={styles.address}>{state}</div>
                                    <div className={styles.phone}>
                                        <strong>Ph:</strong> {phone}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.campuses}>
                            <h5>
                                <strong>{""}</strong>
                            </h5>
                            {campuses5.map(({name, code, address, phone, state, id}) => (
                                <div className={styles.campusContainer} key={id}>
                                    {/* <div className={styles.name}>
                                        <strong>{name}</strong>
                                    </div> */}
                                    <div className={styles.code}>
                                        <strong>{code}</strong>
                                    </div>
                                    <div className={styles.address}>{address}</div>
                                    <div className={styles.address}>{state}</div>
                                    <div className={styles.phone}>
                                        <strong>Ph:</strong> {phone}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.campuses}>
                            <h5>
                                <strong>{""}</strong>
                            </h5>
                            {campuses6.map(({name, code, address, phone, state, id}) => (
                                <div className={styles.campusContainer} key={id}>
                                    {/* <div className={styles.name}>
                                        <strong>{name}</strong>
                                    </div> */}
                                    <div className={styles.code}>
                                        <strong>{code}</strong>
                                    </div>
                                    <div className={styles.address}>{address}</div>
                                    <div className={styles.address}>{state}</div>
                                    <div className={styles.phone}>
                                        <strong>Ph:</strong> {phone}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
